define(['app', 'elementViewed'], function(app, elementViewed) {

  

  var productReviewsVariantATracking = function() {
    var self = this;
    self.elementViewed = elementViewed;

    var _config = {
      scrollLink: '.js-scrollto-product-reviews',
      reviewAccordion: '[data-js-element=reviewaccordion]',
      reviewAccordionBeneath: '[data-js-element=reviewaccordionBeneath]',
      oldReviewSection: '.review-disclaimer'
    };

    var _init = function(element) {
      self.element = element;
      self.scrollToReviewsLink = self.element.querySelector(self.config.scrollLink);
      self.productReviewTab = self.element.querySelector(self.config.reviewAccordion);
      self.productReviewSection = self.element.querySelector(self.config.oldReviewSection);
      self.productReviewSectionBeneath = document.querySelector(self.config.reviewAccordionBeneath);

      self.bind();
      return self;
    };

    var _bind = function() {
      if (self.scrollToReviewsLink) {
        self.scrollToReviewsLink.addEventListener('click', self.scrollToReviews);
      }
      if (self.productReviewSectionBeneath) {
        self.elementViewed(self.productReviewSectionBeneath, self.trackComponentViewed);
      }
      if (self.productReviewTab) {
        self.productReviewTab.addEventListener('click', self.productReviewTabTracking);
      }
      self.elementViewed(self.productReviewSection, self.oldReviewSectionViewedTracking);
    };

    var _scrollToReviews = function() {
      self.elementViewed(self.productReviewSection, self.oldReviewSectionViewedTracking);
    };

    var _productReviewTabTracking = function() {
      self.triggerTrackingEvent('Clicked', 'oldProductReviews clickedProductReviewsTab');
      self.elementViewed(self.productReviewSection, self.oldReviewSectionViewedTracking);
    };

    var _oldReviewSectionViewedTracking = function() {
      if (self.productReviewTab.classList.contains('expand')) {
        self.triggerTrackingEvent('Viewed', 'oldProductReviews reviewSectionViewed');
      }
    };

    var _triggerTrackingEvent = function(action, label) {
      app.publish('tracking/record', 'Product | Old-Review', action, label);
    };

    var _trackComponentViewed = function() {
      self.triggerTrackingEvent('Viewed', 'oldProductReviews component');
    };

    self.config = _config;
    self.init = _init;
    self.bind = _bind;
    self.scrollToReviews = _scrollToReviews;
    self.productReviewTabTracking = _productReviewTabTracking;
    self.oldReviewSectionViewedTracking = _oldReviewSectionViewedTracking;
    self.triggerTrackingEvent = _triggerTrackingEvent;
    self.trackComponentViewed = _trackComponentViewed;
  };

  return productReviewsVariantATracking;
});
